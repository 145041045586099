"use client";

import { datadogRum } from "@datadog/browser-rum";

import { config } from "@ctv/core";

const enabledEnvs = ["dev", "stg", "prod"];

if (config.datadog.env && enabledEnvs.includes(config.datadog.env)) {
  try {
    datadogRum.init(config.datadog);
  } catch (error) {
    // No op.
  }
}

export default function DatadogInitializer() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
